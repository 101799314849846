import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Antonio Dal Santo",
      position: "Geschäftsführer Ristorante Caorle",
      src: "images/testimonial/caorle_logo.png",
      desc: "Ich kann Daniel nur weiterempfehlen. Von Anfang an zeigte er ein tiefes Verständnis für meine Bedürfnisse und setzte meine Vision in ein herausragendes Design um. Was Daniel besonders auszeichnet, ist sein Engagement und seine Hingabe für seine Kunden. Er war immer erreichbar, nahm sich die Zeit, um meine Fragen zu beantworten und ging auf meine Anliegen ein. Sein Kundenservice ist vorbildlich und hat dazu beigetragen, dass ich mich jederzeit gut betreut gefühlt habe. Klare Weiterempfehlung!",
      rating: 5,
    },
    {
      name: "Ursula Helfer",
      position: "Geschäftsführer FinanzHelfer",
      src: "images/testimonial/finanzhelfer.png",
      desc: "Ich bin begeistert von Daniels Arbeit! Sein tiefes Verständnis für Design und Funktionalität hat meine Website auf ein neues Niveau gehoben. Er war nicht nur professionell, sondern auch äußerst flexibel und hat sich immer Zeit genommen, um meine Anliegen zu besprechen. Daniel hat nicht nur meine Erwartungen erfüllt, sondern übertroffen. Ich schätze die Zusammenarbeit mit ihm sehr und werde ihn definitiv für zukünftige Projekte wieder in Betracht ziehen.",
      rating: 5,
    },
    {
      name: "Anonym",
      position: "Anonymous",
      src: "images/testimonial/client-sm-3.png",
      desc: "Die Zusammenarbeit mit Daniel war ein absoluter Glücksgriff! Sein kreativer Ansatz und sein Verständnis für meine Anforderungen haben zu einem fantastischen Design geführt. Daniel zeichnet sich nicht nur durch Professionalität aus, sondern auch durch seine freundliche und serviceorientierte Art. Die klare Kommunikation und sein Einsatz für meine Zufriedenheit haben die Zusammenarbeit zu einer Freude gemacht. Ich kann Daniel jedem empfehlen, der nach einem talentierten und engagierten Webdesigner sucht.",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Feedback
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Kundenmeinung
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
