import React from "react";
import {Link} from "react-scroll";

const mobileOffset = 0;
const AboutUs = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Über mich
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Das bin ich
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              Ich bin <span className="text-primary">Daniel Goroschko,</span> Ihr zukünftiger Webentwickler!
            </h2><br/>
            <p className={darkTheme ? "text-white-50" : ""}>
            Mit fundierter Ausbildung und umfassender Erfahrung als Fullstack-Entwickler biete ich nicht nur kreative Designs, sondern auch eine durchdachte Integration von Technologie und Ästhetik. Doch das ist nicht alles.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            Meine Rolle als Projekt- und Teamleiter hat mir nicht nur organisatorische Fähigkeiten vermittelt, sondern auch ein tiefes Verständnis dafür, wie Technik und Design erfolgreich miteinander verschmelzen können. Diese einzigartige Perspektive setze ich in meiner Arbeit ein, um Websites zu schaffen, die nicht nur technologisch beeindruckend sind, sondern auch ein ansprechendes und benutzerfreundliches Design bieten.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            Wenn Sie auf der Suche nach einem Partner sind, der nicht nur die technischen Anforderungen erfüllt, sondern auch sicherstellt, dass Ihre Website Ihre Vision voll und ganz verkörpert, dann bin ich genau der richtige Ansprechpartner. Kontaktieren Sie mich, um mehr über meine Erfahrungen und die Möglichkeiten für Ihre Online-Präsenz zu erfahren. Lassen Sie uns gemeinsam eine digitale Präsenz gestalten, die nicht nur technisch brillant ist, sondern auch Ihre potenziellen Kunden beeindruckt.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Daniel Goroschko
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:daniel.goroschko@gdaniel.de">daniel.goroschko@gdaniel.de</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Alter:</span>25
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">Wohnort:</span>Cham,
                  Bayern
                </li>
              </ul>
              <Link
                  to="contact"
                  className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                  offset={window.innerWidth < 768 ? mobileOffset : 65}
                  spy
                  smooth
                  duration={500}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick("contact");
                  }}
              >
                Der erste Schritt zu Ihrer Website
              </Link>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>5</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Jahre Erfahrung
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>20</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Zufriedene Kunden
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>20</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  erledigte Projekte
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
