import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Branding & Logo-Design",
      desc: "Ihr einzigartiges Branding startet mit einem markanten Logo als Herzstück. Ich kreiere nicht nur ästhetisch ansprechende, sondern auch aussagekräftige Logos. Gemeinsam erzählen wir Ihre Geschichte und hinterlassen einen bleibenden Eindruck. Lassen Sie uns Ihre Marke definieren und mit durchdachtem Design herausragen.",
      icon: "fas fa-palette",
    },
    {
      name: "Webentwicklung",
      desc: "Mein Fokus liegt auf umfassender Webentwicklung – von individuellen Codes bis hin zu anspruchsvollen Technologien. Ich setze Projekte um, die nicht nur funktional sind, sondern auch ein herausragendes Benutzererlebnis bieten. Gemeinsam gestalten wir Ihre digitale Präsenz und tauchen in die Welt der innovativen Webentwicklung ein.",
      icon: "fas fa-desktop",
    },
    {
      name: "Text & Grafikerstellung",
      desc: "Durch die Kunst der Text- und Grafikerstellung verleihe ich Ihrer Präsenz einen einzigartigen Charme, der Ihre Individualität betont und Ihr Publikum fasziniert. Mit maßgeschneiderten Texten und kreativen Grafiken hebe ich Sie hervor, schaffe Vertrauen in Ihre Botschaft und inspiriere potenzielle Kunden dazu, sich für Ihr Angebot zu begeistern.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Webdesign",
      desc: "Durch ein attraktives und fachkundiges Webdesign gewährleiste ich, dass Sie sich von Mitbewerbern abheben und Ihre Zielgruppe effektiv erreichen. Eine herausragende Website kann das Vertrauen in Ihr Unternehmen stärken und dazu beitragen, dass potenzielle Kunden sich für Ihre Dienstleistungen oder Produkte entscheiden.",
      icon: "fas fa-paint-brush",
    },
    {
      name: "Responsive Design",
      desc: "Durch ein responsives Design kann ich sicherstellen, dass Ihre Website auf jedem Gerät optimal dargestellt wird und Ihre Zielgruppe anspricht. Eine schlecht dargestellte Website kann potenzielle Kunden abschrecken und das Vertrauen in Ihr Unternehmen mindern. Mit einem responsiven Design können wir sicherstellen, dass Ihre Website die bestmögliche Nutzererfahrung bietet, unabhängig davon, welches Gerät verwendet wird.",
      icon: "fas fa-chart-area",
    },
    {
      name: "Suchmaschinenoptimierung (SEO)",
      desc: "Als erfahrener Webdesign-Spezialist erkenne ich die grundlegende Bedeutung von Suchmaschinenoptimierung (SEO) für den Erfolg einer Website. Mein Ziel ist es, Ihnen dabei zu helfen, den Besucherstrom auf Ihrer Website zu erhöhen und die Sichtbarkeit im Online-Bereich zu verbessern. Hierfür entwickele und implementiere ich eine ganzheitliche SEO-Strategie, die präzise auf Ihre individuellen Anforderungen abgestimmt ist.",
      icon: "fas fa-bullhorn",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Was mache ich?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
