import React, {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Modal } from 'bootstrap';

const Contact = ({ classicHeader, darkTheme }) => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);
  
  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        "service_sd84imi",
        "template_6pljfbh",
        form.current,
        "jpN_yA0zjAv2Pmgbu"
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          toast.success("Nachricht erfolgreich abgeschickt! Ich melde mich baldmöglichst bei Ihnen!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          console.log(result.text);
          setSendingMail(false);
        },
        (error) => {
          toast.error("Die Nachricht konnte nicht abgeschickt werden. Bitte versuchen Sie es erneut.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          console.log(error.text);
          setSendingMail(false);
        }
      );
  };

  const openTermsModal = () => {
    const termsModal = new Modal(document.getElementById('terms-policy'));
    termsModal.show();
  };
  
  return (
    <section
      id="contact"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      style={{ minHeight: '95vh' }}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Kontakt
          </h2>
          <p
            className={`text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 ${
                window.innerWidth < 500 ? 'mt-5' : ''
            } ${darkTheme ? 'text-white' : 'text-dark'}`}
          >
            {" "}
            Kontaktieren Sie mich!
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className={`row gy-5 ${window.innerWidth < 500 ? 'mt-5' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* contact details */}
          <div className="col-md-4 col-xl-4 order-1 order-md-0 text-center mb-5">
            <h2
              className={
                "mb-3 text-7 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Adresse
            </h2>
            <p className={"text-4 mb-4 " + (darkTheme ? "text-light" : "")}>
              Daniel Goroschko - Webdesign
              <br />
              Altenmarkter Straße 38
              <br />
              93413 Cham
            </p>
            <p className={"text-4 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" />
              </span>
              +49 176 735 226 23
            </p>           
            <p className={"text-4 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              daniel.goroschko@gdaniel.de
            </p>
          </div>
          {/* contact form */}
          <div className="col-md-8 col-xl-9 order-0 order-md-1">
            <h2
              className={
                "mb-3 text-6 text-uppercase text-center text-md-start " +
                (darkTheme ? "text-white" : "")
              }
            >
              Senden Sie mir eine Nachricht!
            </h2>
            <form
              className={darkTheme ? "form-dark" : "" }
              id="contact-form"
              action="php/mail.php"
              method="post"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-xl-6">
                  <input
                    name="user_name"
                    type="text"
                    className="form-control"
                    required
                    placeholder="Name"
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    name="user_email"
                    type="email"
                    className="form-control"
                    required
                    placeholder="Email"
                  />
                </div>
                <div className="col">
                  <textarea
                    name="message"
                    className="form-control"
                    rows={5}
                    required
                    placeholder="Erzählen Sie mir von Ihrem Vorhaben..."
                    defaultValue={""}
                  />
                </div>
              </div>
              <p className="text-center mt-4 mb-0">
                <input className="form-check-input" style={{verticalAlign : "text-top"}} type="checkbox" required value="" id="flexCheckDefault" />
                <label className="form-check-label text-white mb-3 ms-3" htmlFor="flexCheckDefault">
                  Ja, ich habe den <a href="#terms-policy" onClick={(e) => { e.preventDefault(); openTermsModal(); }}>Datenschutzhinweis</a> zur Kenntnis genommen und bin damit einverstanden.
                </label>
                
                <button
                  id="submit-btn"
                  className="btn btn-primary rounded-pill d-inline-flex"
                  type="submit"
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Wird gesendet...
                    </>
                  ) : (
                    <>Nachricht senden</>
                  )}
                </button>
              </p>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
