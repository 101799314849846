import React from "react";
import Typewriter from "typewriter-effect";
import {Link} from "react-scroll";

const mobileOffset = 0;
const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg.jpg")' }}
        ></div>

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="text-7 fw-500 text-white mb-2 mb-md-3">Willkommen!</h2>
                <h2 className="text-16 fw-600 mb-2 mb-md-3" style={{ color: '#0d6efd' }}>
                  <Typewriter
                    options={{
                      strings: [
                        "Webdesign in Perfektion.",
                        "Digitale Welten, die begeistern.",
                        "Designs, die beeindrucken und überzeugen.",
                        "Designs, die Ihre Online-Präsenz zum Strahlen bringen.",                                                
                      ],
                      autoStart: true,
                      loop: true,
                      delay: 50,
                      deleteSpeed: 50
                    }}
                  />
                </h2>
                <p className="text-5 text-light mb-4">
                  aus Cham, Bayern, Deutschland.
                </p>
                  <Link
                      to="contact"
                      className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={window.innerWidth < 768 ? mobileOffset : 65}
                      onClick={(e) => {
                          e.preventDefault();
                          handleNavClick("contact");
                      }}
                  >
                      Der erste Schritt zu Ihrer Website
                  </Link>
              </div>
            </div>
          </div>
          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
