import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DESIGN: "Webdesign",
    BRAND: "Grafiken",
  };

  const projectsData = [
    {
      title: "Ristorante Caorle - Website",
      projectInfo:
        "Die Website für Ristorante Caorle verbindet die traditionelle Eleganz der italienischen Küche mit modernem Webdesign. Erleben Sie die visuelle Präsentation der Speisekarte und die einladende Atmosphäre. Ansprechende Bilder geben einen Eindruck von den Köstlichkeiten und der herzlichen Stimmung des Restaurants.",
      client: "Ristorante Caorle",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "www.ristorante-caorle.de",
        link: "https://ristorante-caorle.de/",
      },
      thumbImage: "images/projects/Ristorante_Caorle_1.webp",
      sliderImages: [
        "images/projects/Ristorante_Caorle_2.webp",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Beispielprojekt",
      projectInfo:
        "Im Zuge meiner persönlichen Entwicklungsübung im Bereich Webentwicklung/Webdesign präsentiere ich stolz eines meiner Beispielprojekte. Diese nicht veröffentlichte Portfolio-Website dient als Demonstration meiner Fähigkeiten im Webdesign und bietet einen Einblick in meine gestalterische Vielseitigkeit.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/UI_Portfolio_1.webp",
      sliderImages: [
        "images/projects/UI_Portfolio_2.webp",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Finanzhelfer - Website",
      projectInfo:
        "In die Welt des frühzeitigen Vermögensaufbaus, gestaltet durch ein ansprechendes und leicht zugängliches Webdesign. Diese Website ist mein Beitrag, um Finanzbildung nicht nur informativ, sondern auch inspirierend zu gestalten.",
      client: "Finanzhelfer",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "www.finanz-helfer.com",
        link: "https://finanz-helfer.com/",
      },
      thumbImage: "images/projects/Finanzhelfer_1.webp",
      sliderImages: [
        "images/projects/Finanzhelfer_2.webp",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Illustration Beispiel",
      projectInfo:
        "Hier präsentiere ich eine Illustration, die während meiner Reise als Webdesigner entstanden ist. Diese Grafik veranschaulicht mein gestalterisches Können und die kreative Ausdruckskraft, die ich in meine Projekte einfließen lasse. Die sanften Linien und harmonischen Farben wurden sorgfältig gewählt, um eine ästhetisch ansprechende Darstellung zu schaffen. Als Webdesigner ist es meine Leidenschaft, visuelle Elemente zu gestalten, die nicht nur ansprechend sind, sondern auch die Botschaften meiner Kunden authentisch und wirkungsvoll vermitteln.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/Illustration_1.webp",
      categories: [filters.BRAND],
    },
    {
      title: "Illustration Beispiel",
      projectInfo:
        "Hier präsentiere ich eine Illustration, die während meiner Reise als Webdesigner entstanden ist. Diese Grafik veranschaulicht mein gestalterisches Können und die kreative Ausdruckskraft, die ich in meine Projekte einfließen lasse. Die sanften Linien und harmonischen Farben wurden sorgfältig gewählt, um eine ästhetisch ansprechende Darstellung zu schaffen. Als Webdesigner ist es meine Leidenschaft, visuelle Elemente zu gestalten, die nicht nur ansprechend sind, sondern auch die Botschaften meiner Kunden authentisch und wirkungsvoll vermitteln.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/Illustration_2.webp",
      categories: [filters.BRAND],
    },
    {
      title: "Logo Beispiel",
      projectInfo:
        "Hier präsentiere ich ein Logo, das auf meiner Reise als Webdesigner entstanden ist. Dieses Design vermittelt nicht nur ästhetische Elemente, sondern auch meine Fähigkeit, wirkungsvolle visuelle Identitäten zu schaffen. Die klaren Linien, das moderne Farbschema und die bewusste Auswahl von Designelementen unterstreichen meine Designphilosophie. Als Webdesigner liegt mein Fokus darauf, Logos zu entwickeln, die nicht nur visuell ansprechend sind, sondern auch die individuellen Markenbotschaften meiner Kunden wirkungsvoll repräsentieren.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/Illustration_3.webp",
      categories: [filters.BRAND],
    },
    {
      title: "Grafik Beispiel",
      projectInfo:
          "Hier präsentiere ich eine Grafik, die auf meiner Reise als Webdesigner entstanden ist. Dieses Design vermittelt nicht nur ästhetische Elemente, sondern auch meine Fähigkeit, wirkungsvolle visuelle Identitäten zu schaffen. Die klaren Linien, das moderne Farbschema und die bewusste Auswahl von Designelementen unterstreichen meine Designphilosophie. Als Webdesigner liegt mein Fokus darauf, Grafiken zu entwickeln, die nicht nur visuell ansprechend sind, sondern auch die individuellen Markenbotschaften meiner Kunden wirkungsvoll repräsentieren.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/Beispiel_Bild_1.webp",
      categories: [filters.BRAND],
    },
    {
      title: "Grafik Beispiel",
      projectInfo:
          "Hier präsentiere ich eine Grafik, die auf meiner Reise als Webdesigner entstanden ist. Dieses Design vermittelt nicht nur ästhetische Elemente, sondern auch meine Fähigkeit, wirkungsvolle visuelle Identitäten zu schaffen. Die klaren Linien, das moderne Farbschema und die bewusste Auswahl von Designelementen unterstreichen meine Designphilosophie. Als Webdesigner liegt mein Fokus darauf, Grafiken zu entwickeln, die nicht nur visuell ansprechend sind, sondern auch die individuellen Markenbotschaften meiner Kunden wirkungsvoll repräsentieren.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/Beispiel_Bild_2.webp",
      categories: [filters.BRAND],
    },
    {
      title: "Grafik Beispiel",
      projectInfo:
          "Hier präsentiere ich eine Grafik, die auf meiner Reise als Webdesigner entstanden ist. Dieses Design vermittelt nicht nur ästhetische Elemente, sondern auch meine Fähigkeit, wirkungsvolle visuelle Identitäten zu schaffen. Die klaren Linien, das moderne Farbschema und die bewusste Auswahl von Designelementen unterstreichen meine Designphilosophie. Als Webdesigner liegt mein Fokus darauf, Grafiken zu entwickeln, die nicht nur visuell ansprechend sind, sondern auch die individuellen Markenbotschaften meiner Kunden wirkungsvoll repräsentieren.",
      client: "Daniel Goroschko",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      jahr: "2023",
      url: {
        name: "N/A",
      },
      thumbImage: "images/projects/Beispiel_Bild_3.webp",
      categories: [filters.BRAND],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Referenzen
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                Alle
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            {/* <span className="text-light">Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
