import React from "react";

const Resume = ({ classicHeader, darkTheme, handleNavClick }) => {
  const skills = [
    {
      name: "Engagement",
      percent: 100,
    },
    {
      name: "Zuverlässigkeit",
      percent: 100,
    },
    {
      name: "Web Design",
      percent: 80,
    },
    {
      name: "HTML/CSS",
      percent: 95,
    },
    {
      name: "JavaScript",
      percent: 70,
    },
    {
      name: "React JS",
      percent: 60,
    },
    {
      name: "Angular Js",
      percent: 70,
    },
    {
      name: "Bootstrap",
      percent: 80,
    },
    {
      name: "C#",
      percent: 70,
    },
    {
      name: "Typescript",
      percent: 70,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Erfahrung
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Skills
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Meine Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            href="#contact"
            className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("contact");
            }}
          >
            Der erste Schritt zu Ihrer Website
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
