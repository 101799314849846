import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h2 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Impressum
            </h2>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h4 className="text-white">Angaben gemäß § 5 TMG</h4>
            <p>
            Daniel Goroschko - Webdesign<br></br>
            Daniel Goroschko<br></br>
            Altenmarkter Straße 38<br></br>
            93413 Cham
            </p>
            <h4 className="text-white">
            Kontakt
            </h4>
            <p>
            Telefon: 0176 / 73522623<br></br>
            E-Mail: daniel.goroschko@gdaniel.de<br></br>
            </p>
            <h4 className="text-white">
            EU-Streitschlichtung
            </h4>
            <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br></br>
            https://ec.europa.eu/consumers/odr/.<br></br>
            Unsere E-Mail-Adresse finden Sie oben im Impressum.<br></br>
            </p>
            <h4 className="text-white">
            Verbraucherstreitbeilegung/
              Universalschlichtungsstelle<br></br>
            </h4>
            <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
