import React from "react";
import ReactDOM from "react-dom/client";

// for rtl format comment out bootstrap.min.css and uncomment bootstrap.rtl.min.css
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.rtl.min.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./sass/stylesheet.scss";

import "./sass/color-blue.scss"

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CookieConsent from "react-cookie-consent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <CookieConsent
          location="bottom"
          buttonText="Ich akzeptiere"
          style={{ zIndex: 9999, alignItems: "center" }}
          className="bg-dark-2"
          buttonStyle={{ backgroundColor: "#0d6efd", fontSize: "15px", border: "1px solid black", borderRadius: "10px" }}>
          Ich benutze Cookies, um sicherzustellen, dass Sie die optimale Benutzererfahrung erhalten. Durch die Nutzung meiner Website erklären Sie sich mit der Verwendung von Cookies einverstanden.
      </CookieConsent>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
